var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0 elevation-0 white",staticStyle:{"max-height":"100%"}},[_c('div',{staticClass:" my-border"},[_c('v-card-title',{staticClass:"py-0 my-0 customlightgray"},[_c('v-row',{staticClass:"ma-0 pa-0 py-2 justify-center text-left"},[_c('v-col',{staticClass:"pa-0 ma-auto",attrs:{"cols":"6","sm":"4","md":"4","lg":"4","order":"1","order-md":"1"}},[_c('div',{staticClass:"font-weight-medium text-left text--secondary"},[_c('div',{staticClass:"ma-auto pa-auto"},[_c('span',{staticClass:"ma-0 pa-1"},[_vm._v(_vm._s(_vm.$t('LogsHistory')))]),_c('span',{staticClass:"custom-color-accent pa-1",staticStyle:{"border-radius":"22px","font-size":"0.6em"}},[_vm._v(_vm._s(_vm.filteredItems.length)+" ")])])])]),_c('v-col',{staticClass:"ma-0 pa-0 text-center",attrs:{"order":"3","order-md":"2","cols":"12","sm":"12","md":"4","lg":"4"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"dense":"","outlined":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"text--secondary",on:{"click":function($event){_vm.dialog_adv=true}}},'v-icon',attrs,false),on),[_vm._v("mdi-cog")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("advanced")))])])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right pa-0 my-2 ma-md-auto",attrs:{"cols":"6","sm":"8","md":"4","lg":"4","order":"2","order-md":"3"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog_adv),callback:function ($$v) {_vm.dialog_adv=$$v},expression:"dialog_adv"}},[_c('v-card',{staticClass:"customoffwhite"},[_c('v-card-title',{staticClass:"justify-center ma-0 customlightgray"},[_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.$t("advanced")))])]),_c('v-divider'),_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-container',{staticClass:"ma-0"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"ma-0 py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.modules,"dense":"","small-chips":"","multiple":"","outlined":"","clearable":"","label":_vm.$t('Modules'),"placeholder":_vm.$t('Modules'),"return-object":""},model:{value:(_vm.filters.module),callback:function ($$v) {_vm.$set(_vm.filters, "module", $$v)},expression:"filters.module"}})],1),_c('v-col',{staticClass:"ma-0 py-0",attrs:{"cols":"12"}},[_c('v-menu',{staticClass:"ma-0",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"multiple":"","chips":"","dense":"","clearable":"","outlined":"","small-chips":"","label":_vm.$t('daterange'),"placeholder":_vm.$t('daterange'),"prepend-inner-icon":"mdi-calendar","readonly":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"custom-color-accent",attrs:{"input-value":data.selected,"small":""}},[_c('strong',[_vm._v(_vm._s(data.item))])])]}}],null,true),model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}},'v-combobox',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":"","max":_vm.nowDate},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"ma-0 py-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"custom-color-accent-text",attrs:{"text":""},on:{"click":function($event){_vm.dialog_adv = false}}},[_vm._v(_vm._s(_vm.$t("close")))]),_c('v-btn',{staticClass:"custom-color-accent-text",attrs:{"text":""},on:{"click":function($event){return _vm.filterLogs()}}},[_vm._v(_vm._s(_vm.$t("search")))])],1)],1)],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterList.length>0),expression:"filterList.length>0"}]}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterList.length>0),expression:"filterList.length>0"}],staticClass:"pa-0 ma-0 white"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-scroll-x-transition',{attrs:{"group":"","hide-on-leave":""}},_vm._l((_vm.filterList),function(f,i){return _c('v-tooltip',{key:i,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"custom-color-accent","close":"","small":"","close-icon":"mdi-delete"},on:{"click:close":function($event){return _vm.removeFilter(f)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(f)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("removefilter")))])])}),1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0 customoffwhite text--secondary pa-0 ma-0",attrs:{"mobile-breakpoint":1081,"items":_vm.filteredItems,"headers":_vm.headers,"search":_vm.search,"loading-text":_vm.$t('loading'),"sort-by":"created_at","sort-desc":true,"footer-props":{'items-per-page-text':_vm.$t('rowsperpage') },"items-per-page":15},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noDataAvailable')))]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.created_at))]}},{key:"item.field",fn:function(ref){
var item = ref.item;
return [(!item.field && !item.old_data && !item.new_data)?_c('span',{staticClass:"custom-color-accent-text text-uppercase"},[_vm._v(_vm._s(_vm.$t('New')))]):_c('span',[_vm._v(_vm._s(item.field))])]}},{key:"item.old_data",fn:function(ref){
var item = ref.item;
return [(!item.field && !item.old_data && !item.new_data)?_c('span',{staticClass:"custom-color-accent-text text-uppercase"},[_vm._v(_vm._s(_vm.$t('New')))]):_c('span',[_vm._v(_vm._s(item.old_data))])]}},{key:"item.new_data",fn:function(ref){
var item = ref.item;
return [(!item.field && !item.old_data && !item.new_data)?_c('span',{staticClass:"custom-color-accent-text text-uppercase"},[_vm._v(_vm._s(_vm.$t('New')))]):_c('span',[_vm._v(_vm._s(item.new_data))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 pa-0 text-right"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.openInNew(item.module,item.item_id)}}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("openinnew")))])])],1)],1)]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }