<template>
<v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{$t('LogsHistory')}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{filteredItems.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
            </v-col>
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
               <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                      v-model="filters.module"
                      :items="modules"
                      dense
                      small-chips
                      multiple
                      outlined
                      clearable
                      :label="$t('Modules')"
                      :placeholder="$t('Modules')"
                      return-object
                    ></v-autocomplete>
               </v-col>
               <v-col cols="12" class="ma-0 py-0">
                 <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.date"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('daterange')"
                        :placeholder="$t('daterange')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.date" :max="nowDate"></v-date-picker>
                  </v-menu>
               </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog_adv = false">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="filterLogs()">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-divider  v-show="filterList.length>0"></v-divider>       
    <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
      <v-col class="pa-0 ma-0" cols="12">
        <v-scroll-x-transition group hide-on-leave>
        <v-tooltip v-for="(f, i) in filterList" :key="i" right>
          <template v-slot:activator="{ on, attrs }">
            <v-chip  v-on='on' v-bind="attrs"
              class="ma-1"
              color="custom-color-accent"
              close
              small
              close-icon="mdi-delete"
              @click:close="removeFilter(f)" >
                {{f}}
              </v-chip>
            </template>
            <span>{{$t("removefilter")}}</span>
          </v-tooltip>
        </v-scroll-x-transition>
      </v-col>
    </v-row> 
      <v-divider></v-divider>
  <v-data-table :mobile-breakpoint="1081"  
    :items="filteredItems"
    :headers="headers"
    class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
    :search="search"
    :loading-text="$t('loading')"
    sort-by="created_at"  :sort-desc="true"
    :footer-props="{'items-per-page-text':$t('rowsperpage') }"
    :items-per-page="15"
  >
    <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
    <template v-slot:[`item.created_at`]="{item}">{{ item.created_at  }}</template>
    <template v-slot:[`item.field`]="{item}"><span v-if="!item.field && !item.old_data && !item.new_data" class="custom-color-accent-text text-uppercase">{{$t('New') }}</span><span v-else>{{item.field}}</span></template>
    <template v-slot:[`item.old_data`]="{item}"><span v-if="!item.field && !item.old_data && !item.new_data" class="custom-color-accent-text text-uppercase">{{$t('New') }}</span><span v-else>{{item.old_data}}</span></template>
    <template v-slot:[`item.new_data`]="{item}"><span v-if="!item.field && !item.old_data && !item.new_data" class="custom-color-accent-text text-uppercase">{{$t('New') }}</span><span v-else>{{item.new_data}}</span></template>
    <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
      <v-row class="ma-0 pa-0 text-right">
        <v-col cols="12" class="ma-0 pa-0">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on='on' v-bind="attrs">
              <v-icon @click.stop="openInNew(item.module,item.item_id)">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <span>{{$t("openinnew")}}</span>
        </v-tooltip>
        </v-col>
      </v-row>
    </template> 
  </v-data-table>
  </div>
  </v-card>
</template>

<script>
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
export default {
  name: "LogsHistory",
  async created() {
      await axcessApi.getRequest('logshistory').then( response => {
           this.items=response.data;
           this.filteredItems=response.data;
    });
    this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
    this.filterList = this.filterList.filter(el=> el != "");
  },
  watch:{
  },
  data(){
    return {
      filters:{
        module:[],
        date:[],
      },
      items:[],
      filteredItems:[],
      filterList:[],
      nowDate:new moment().toISOString(),
      dialog_adv:false,
      modules: ['template','requests','tasks','complaint','court','budget','expense','attachments','users','address','email','costs','fees'],
      search:'',
      headers: [ 
        { text: this.$t('ID') , value: "id" },
        { text: this.$t('Module') , value: "module" },
        { text: this.$t('Field') , value: "field" },
        { text: this.$t('old_data') , value: "old_data"},
        { text: this.$t('new_data') , value: "new_data"},
        { text: this.$t('datecreated') , value: "created_at"},
        { text: '' , value: "action"},
      ]
    }
  },
  methods: {
    removeFilter(item){
      Object.keys(this.filters).forEach((key)=>{
      if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
        this.filters[key]={text:"",value:""};        
      else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
        this.filters[key]="";           

      else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
      this.filters[key]= this.filters[key].filter(a=>a.text!=item);

      else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
      this.filters[key]= this.filters[key].filter(a=>a!=item);
      });
      this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
      this.filterList = this.filterList.filter(el=> el != "");
      this.filterLogs();
    }, 
    filterLogs(){
      this.filteredItems=[];
      Object.assign(this.filteredItems,this.items)
      if(this.filters.date.length == 1)
        this.filteredItems=this.filteredItems.filter(item => new Date(moment(item.created_at).format("YYYY-MM-DD")).getTime() == new Date(moment(this.filters.date[0]).format("YYYY-MM-DD")).getTime())
      if(this.filters.date.length == 2)
        this.filteredItems=this.filteredItems.filter(item=>new Date(moment(item.created_at ).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.filters.date[0]).format("YYYY-MM-DD")).getTime() && 
                                    new Date(moment(this.filters.date[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.created_at).format("YYYY-MM-DD")).getTime())
      if(this.filters.module.length>0)
        this.filteredItems=this.filteredItems.filter(item=>this.filters.module.includes(item.module))
        
        this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
        this.filterList = this.filterList.filter(el=> el != "");
        this.dialog_adv=false;
    },
    openInNew(module,id){
      if(this.modules.includes(module)){
        if(module=='budget' || module=='expense')
          module='finance';

        if(module=='attachments' || module=='email' || module=='costs' || module=='fees')
          return

        let r={name:module};
        if(module=='tasks' || module=='requests')
          r={name:module,params:{id:id}}

        let routeData = this.$router.resolve(r);
        window.open(routeData.href, '_blank');
      }
    },
  }
}
</script>

<style scoped>

</style>